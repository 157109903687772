import React from "react";
import { Helmet } from "react-helmet";
import SliderSection from "../components/common/SliderSection";
import  Arrow  from "../components/common/Arrow";
import Server from "../images/server3.png";
import backImage from "../images/backImage.png";
const atest = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
      </Helmet>
      <div className="flex justify-center">
        {/* <Slider
        imageLinks={[Server, backImage]}
        sliderTimer={1000000}
        frontImageDirection="right"
      /> */}
        <div>
          <SliderSection 
          images={[Server, backImage, Server]} 
          title="Physical Data Center Solutions"
          description="Partnering with Unboggled gives you a guarantee for competitiveness, continuity and uptime for your business operations. Our flexibility, proficiency and forward-looking data center solutions ensure you can choose the ideal option that works best for you"
          />
        </div>
      </div>
      <div className="h-64 flex relative">
        <Arrow direction="next"/>
        <Arrow direction="prev"/>
      </div>
    </>
  );
};

export default atest;
